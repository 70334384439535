// @flow strict
import React from 'react';
import styles from './Books.module.scss';

type Props = {
  body: string
};

const Book = ({ body }: Props) => (
  <div className={styles['book-rec']} dangerouslySetInnerHTML={{ __html: body }} />
);

export default Book;
